import { format } from 'date-fns'
import { DATE_FORMAT } from '../../utils/date-formatting.js'
import { configApi } from '../http-client.js'

export const getExtendedPreferences = () => configApi.get('/api/ExtendedPreferences').then(({ data }) => ({
	extendedPreferences: data.extendedPreferences.map(extendedPreference => ({
		...extendedPreference,
		dateAdded: format(new Date(extendedPreference.dateAdded), DATE_FORMAT)
	})),
	lookupData: data.lookupData
}))

export const createExtendedPreference = extendedPreference => configApi.post('/api/ExtendedPreferences', extendedPreference)

export const updateExtendedPreference = extendedPreference => configApi.put('/api/ExtendedPreferences', extendedPreference)

export const deleteExtendedPreference = extendedPreferenceId => configApi.delete(`/api/ExtendedPreferences/${extendedPreferenceId}`)

export const upsertExtendedPreferenceTranslation = translation => configApi.post('/api/ExtendedPreferences/UpsertTranslation', translation)

export const deleteExtendedPreferenceTranslation = translationToDelete => configApi.delete(`/api/ExtendedPreferences/DeleteTranslation?valueId=${translationToDelete.valueId}&languageId=${translationToDelete.languageId}`)
