<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="800px">
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						v-model="extendedPreference.name"
						:disabled="readOnlyPermissions"
						label="Name *"
						rules="required"
					/>
					<div
						class="cassie-vertical-md"
					>
						<div class="cassie-horizontal-sm d-flex flex-row">
							<TextField
								v-model="dropdownItem"
								:disabled="readOnlyPermissions"
								class="cassie-input-width-xl"
								label="Dropdown Menu Item"
							/>
							<PrimaryActionButton
								v-if="!readOnlyPermissions"
								:disabled="!dropdownItem"
								@click="addDropdownItem"
							>
								Add
							</PrimaryActionButton>
						</div>
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Dropdown Menu Item
										</th>
										<th
											scope="col"
											class="text-right"
										>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in extendedPreference.dropdownValues"
										:key="item.displayOrder"
									>
										<td>
											{{ item.value }}
										</td>
										<td class="text-right">
											<IconButton
												v-if="index !== 0"
												:disabled="readOnlyPermissions"
												tooltip-text="Move Dropdown Value Up"
												@click="moveDropdownValueUp(index)"
											>
												mdi-arrow-up
											</IconButton>
											<IconButton
												v-if="index !== extendedPreference.dropdownValues.length - 1"
												:disabled="readOnlyPermissions"
												tooltip-text="Move Dropdown Value Down"
												@click="moveDropdownValueDown(index)"
											>
												mdi-arrow-down
											</IconButton>
											<IconButton
												v-if="!readOnlyPermissions"
												tooltip-text="Remove Dropdown Value"
												@click="removeDropdownValue(index)"
											>
												mdi-close
											</IconButton>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { createExtendedPreference, updateExtendedPreference } from '../../../../../shared/utils/api/extended-preferences.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	name: 'manage-extended-preference-modal',
	components: { PrimaryActionButton, SecondaryActionButton, TextField, Modal, IconButton },
	props: {
		extendedPreferenceToEdit: Object,
		extendedPreferences: Array
	},
	data () {
		return {
			extendedPreference: JSON.parse(JSON.stringify(this.extendedPreferenceToEdit || {
				name: '',
				dropdownValues: []
			})),
			dropdownItem: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.extendedPreferenceToEdit)
		},
		modalTitle () {
			return this.isEdit ? 'Edit Extended Preference' : 'Add Extended Preference'
		},
		readOnlyPermissions () {
			return !this.checkPermission(EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{
					text: 'Dropdown Item',
					value: 'value'
				},
				{
					text: 'Action',
					value: 'action',
					align: 'right'
				}
			]
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		addDropdownItem () {
			const dropdownItem = {
				value: this.dropdownItem
			}
			this.extendedPreference.dropdownValues.push(dropdownItem)
			this.dropdownItem = null
		},
		moveDropdownValueDown (index) {
			this.move(this.extendedPreference.dropdownValues, index, index + 1)
		},
		moveDropdownValueUp (index) {
			this.move(this.extendedPreference.dropdownValues, index, index - 1)
		},
		removeDropdownValue (index) {
			const dropdownValues = [...this.extendedPreference.dropdownValues]
			this.$delete(dropdownValues, index)
			this.extendedPreference.dropdownValues = dropdownValues
		},
		applySort (array) {
			this.extendedPreference.dropdownValues = array
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		async submit () {
			const extendedPreference = {
				name: this.extendedPreference.name,
				dropdownValues: this.extendedPreference.dropdownValues
			}
			extendedPreference.dropdownValues.forEach((dropdownValue, index) => {
				dropdownValue.displayOrder = index + 1
			})

			const duplicateName = Boolean(this.extendedPreferences.find(
				({ name, extendedPreferenceId }) => name === this.extendedPreference.name && extendedPreferenceId !== this.extendedPreference.extendedPreferenceId
			))
			if (duplicateName) {
				showSnackbar({ color: 'red', text: 'Extended Preference Key already exists with this name, please enter another name' })
			} else {
				if (!this.isEdit) {
					await createExtendedPreference(extendedPreference)
				} else {
					extendedPreference.extendedPreferenceKeyId = this.extendedPreference.extendedPreferenceId
					delete extendedPreference.extendedPreferenceId
					await updateExtendedPreference(extendedPreference)
				}
				this.close()
				this.$emit('submit')
				showSnackbar(this.isEdit ? 'You have updated this extended preference' : 'You have created a new extended preference')
			}
		}
	}
}
</script>
