<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="extendedPreferencesSearchQuery"
			search-query-label="Extended Preference Key Name"
			hide-brand-filter
			@persistSearchQuery="changeExtendedPreferencesSearchQuery"
		>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="extendedPreferences.length < noOfAllowedExtendedPrefKeys ? showAddModal = true : extendedPreferenceKeyLimitReached()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Extended Preferences
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredExtendedPreferences"
					sort-by="extendedPreferenceId"
					sort-desc
					@click:row="rowClick"
				>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Edit Extended Preference"
							@click="extendedPreferenceToEdit = item"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
						<IconButton
							v-if="!readOnlyPermissions"
							tooltip-text="Delete Extended Preference"
							@click.stop.prevent="extendedPreferenceToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
				<ConfirmDeleteModal
					v-if="extendedPreferenceToRemove"
					:entity-name="extendedPreferenceToRemove.name"
					entity-type="Extended Preference"
					@close="extendedPreferenceToRemove = null"
					@delete="deleteExtendedPreference"
				/>
				<ManageExtendedPreferenceModal
					v-if="showAddModal || extendedPreferenceToEdit"
					:extended-preferences="extendedPreferences"
					:extended-preference-to-edit="extendedPreferenceToEdit"
					@close="closeManageExtendedPreferenceModal"
					@submit="$emit('load-extended-preferences')"
				/>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ManageExtendedPreferenceModal from './manage-extended-preference-modal.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import { changeExtendedPreferencesSearchQuery, extendedPreferencesSearchQuery } from '../../../../../shared/state/extended-preferences.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { deleteExtendedPreference } from '../../../../../shared/utils/api/extended-preferences.js'
import { noOfAllowedExtendedPrefKeys, waitForLoad } from '../../../../../shared/state/config-keys.js'
import { EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: { DataTable, FilterBar, PrimaryActionButton, ConfirmDeleteModal, ManageExtendedPreferenceModal, SectionCard, IconButton },
	props: {
		extendedPreferences: Array
	},
	setup () {
		return {
			noOfAllowedExtendedPrefKeys,
			waitForLoad,
			changeExtendedPreferencesSearchQuery,
			extendedPreferencesSearchQuery
		}
	},
	data () {
		return {
			extendedPreferenceToEdit: null,
			extendedPreferenceToRemove: null,
			tableHeaders: [
				{
					value: 'extendedPreferenceId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Extended Preference Key Name',
					width: '50%'
				},
				{
					value: 'dateAdded',
					text: 'Created Date',
					width: '25%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			],
			showAddModal: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		filteredExtendedPreferences () {
			return this.extendedPreferences.filter(({ name }) => {
				let check = true
				if (extendedPreferencesSearchQuery.value) check = name.toLowerCase().includes(extendedPreferencesSearchQuery.value.toLowerCase())
				return check
			})
		},
		readOnlyPermissions () {
			return !this.checkPermission(EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS)
		}
	},
	async created () {
		await waitForLoad()
	},
	methods: {
		closeManageExtendedPreferenceModal () {
			this.extendedPreferenceToEdit = null
			this.showAddModal = false
		},
		extendedPreferenceKeyLimitReached () {
			showSnackbar({ text: 'Extended Preference Key Limit Reached', color: 'red' })
		},
		rowClick (row) {
			this.extendedPreferenceToEdit = row
		},
		async deleteExtendedPreference () {
			await deleteExtendedPreference(this.extendedPreferenceToRemove.extendedPreferenceId)
			this.$emit('load-extended-preferences')
			showSnackbar('You have removed this extended preference')
			this.extendedPreferenceToRemove = null
		}
	}
}
</script>
