<template>
	<ViewLayout>
		<template #header-title>
			Extended Preferences
		</template>
		<template #header-caption>
			Add, edit and manage extended preferences
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<ExtendedPreferencesTab
						:extended-preferences="extendedPreferences"
						@load-extended-preferences="loadExtendedPreferences"
					/>
				</template>
				<template #1>
					<ExtendedPreferencesTranslationsTab
						:extended-preferences="extendedPreferences"
						@load-extended-preferences="loadExtendedPreferences"
					/>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import ExtendedPreferencesTab from './extended-preferences-tab.vue'
import ExtendedPreferencesTranslationsTab from './extended-preferences-translations-tab.vue'
import { getExtendedPreferences } from '../../../../../shared/utils/api/extended-preferences.js'

export default {
	components: {
		ViewLayout,
		Tabs,
		TabItems,
		ExtendedPreferencesTab,
		ExtendedPreferencesTranslationsTab
	},
	props: {
		accessedViaTranslation: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			tabs: [{ title: 'Extended Preferences', slot: '0' }, { title: 'Extended Preference Translations', slot: '1' }],
			tab: '0',
			extendedPreferences: []
		}
	},
	async created () {
		this.loadExtendedPreferences()
		if (this.accessedViaTranslation) {
			this.tab = '1'
		}
	},
	methods: {
		async loadExtendedPreferences () {
			const { extendedPreferences } = await getExtendedPreferences()
			this.extendedPreferences = extendedPreferences
		}
	}
}
</script>
