<template>
	<v-tabs
		:value="tab"
		:height="embedded ? undefined : 70"
		:left="embedded"
		:grow="!embedded"
		:class="{
			'rounded-b-0': embedded,
		}"
		:dark="embedded"
		@change="$emit('update:tab', $event)"
	>
		<v-tab
			v-for="{ title, slot, disabled: tabDisabled } in tabs"
			:key="slot"
			:disabled="disabled || tabDisabled"
			:href="`#${slot}`"
			:active-class="`${embedded ? '' : 'blue lighten-5 blue--text'}`"
			class="text-capitalize"
			:class="{
				'px-10 py-5': embedded,
			}"
		>
			{{ title }}
		</v-tab>
	</v-tabs>
</template>

<script>
export default {
	props: {
		tab: {
			type: String,
			required: true
		},
		tabs: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		embedded: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss">
.cassie-tabs {
  box-shadow: var(--global-cassie-tabs-container-box-shadow);
}

.cassie-tab {
  border-right: var(--global-cassie-tab-border-right);
  &:nth-last-child(1) {
    border-right: none;
  }
}
</style>
